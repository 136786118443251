import axios from "axios";
const TOKEN = "Bearer 64cda7184d52fee957124d505df02576";

export default {
    async getUser(userId) {
        const response = await axios.get(`/view/users/${userId}`, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
