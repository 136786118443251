
import { modalController } from "@ionic/vue";

import ClienteDetail from "../views/ClienteDetail.vue";

export default {
    name: "CustomerItem",
    props: {
        source: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, context) {
        //console.log(props);

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };
        /**
         * Passing customer object ClienteDetail page
         */
        async function openDetailModal(customer) {
            //console.log(customer);
            const modal = await modalController.create({
                component: ClienteDetail,
                componentProps: {
                    data: customer,
                },
            });
            return modal.present();
        }

        return {
            riferimentoCliente,
            indirizzoCliente,
            openDetailModal,
        };
    },
};
