<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="dark"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title>Nuovo cliente</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="createCustomer()">
                    <ion-list class="fields">
                        <!-- Name -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Nome
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="customer.name" placeholder="Mario"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cognome -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Cognome
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="customer.last_name" placeholder="Rossi"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Address -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Indirizzo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="customer.address" placeholder="via Garibaldi 24"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- City -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Città
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="customer.city" placeholder="Roma"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Province -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Provincia <span class="helper_text">(Max 2 caratteri)</span></div>
                                <div class="value">
                                    <ion-input type="text" maxlength="2" v-model="customer.province" placeholder="RM" @ionChange="onChange($event)">
                                    </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Telephone -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Telefono
                                </div>
                                <div class="value">
                                    <ion-input type="tel" inputmode="tel" v-model="customer.telephone" placeholder="3391234567"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <ion-button size="default" type="submit" expand="block" class="btn_nuovo_cliente">
                                Salva
                            </ion-button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonText,
    IonIcon,
    IonInput,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive } from "vue";

import moment from "moment";

import { openToast } from "../services/toast";

import apiClienti from "../services/clienti";

export default defineComponent({
    name: "ModalNewCustomer",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonText,
        IonIcon,
        IonInput,
    },
    setup(props, context) {
        const customer = reactive({
            name: "",
            last_name: "",
            address: "",
            city: "",
            province: "",
            telephone: "",
        });

        const successResponse = ref(false);

        const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

        const onChange = (e) => {
            if (e.target.value.length > 2) {
                e.target.value = e.target.value.substring(0, 2);
            }
        };

        /**
         * Close modal after created new customer
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                //console.log(res);
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new customer
         */
        function createCustomer() {
            if (!customer.name || !customer.last_name) {
                openToast("Nome e cognome sono obbligatori", "toast_danger");
                return;
            } else {
                fieldFlag.value = true;
            }

            //console.log("fieldFlag = ", fieldFlag.value);
            if (fieldFlag.value) {
                const data = new FormData();
                data.append("customers_type", "1");
                //data.append("customers_sotto_tipo", "1");
                data.append("customers_group", "1");
                data.append("customers_name", customer.name);
                data.append("customers_last_name", customer.last_name);
                data.append("customers_address", customer.address);
                data.append("customers_city", customer.city);
                data.append("customers_province", customer.province);
                data.append("customers_phone", customer.telephone);

                // Display formData values
                /*for (const value of data.entries()) {
                  console.log(value[0] + ", " + value[1]);
                } */

                apiClienti
                    .saveCliente(data)
                    .then((response) => {
                        if (response.data.status == 8) {
                            openToast(response.data.message, "toast_danger");
                        } else {
                            const res = response.data.data[0];
                            successResponse.value = true;
                            closeModalOnSubmit(successResponse, res);
                        }
                    })
                    .catch((error) => {
                        // handle error
                        console.error(error);
                        successResponse.value = false;
                        openToast("Errore durante la creazione del cliente", "toast_danger");
                    })
                    .finally(() => {
                        //console.log("fine creazione feedback");
                    });
            }
        }

        return {
            customer,
            createCustomer,
            closeModal,
            arrowBackOutline,
            onChange,
        };
    },
});
</script>

<style scoped>
.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.example {
    border: 1px solid black;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    margin-bottom: 4px;
    --ripple-color: transparent;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
    color: red;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
    color: red;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

.helper_text {
    color: #9ca3af;
    font-size: 12px;
}

.btn_nuovo_cliente {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
</style>
