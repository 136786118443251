
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonInput,
    modalController,
    IonButtons,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh } from "ionicons/icons";

import { ref, onMounted, computed, shallowRef } from "vue";

import { useRouter } from "vue-router";

import moment from "moment";
import { openToast } from "@/services/toast";

import apiClienti from "@/services/clienti";
import apiUtenti from "@/services/user";

import ClienteDetail from "./ClienteDetail.vue";

import ModalNewCustomer from "@/components/ModalNewCustomer.vue";
import CustomerItem from "@/components/CustomerItem.vue";

import VirtualList from "vue3-virtual-scroll-list";

import { dbUser, dbCustomers, getCustomers } from "@/services/localbase";

/* eslint-disable vue/no-unused-components */
export default {
    name: "TabClienti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
        VirtualList,
        CustomerItem,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

        const router = useRouter();

        const clienti = ref([]);
        const searchQuery = ref("");

        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            if (term.length >= 2) {
                return clienti.value.filter((cliente) => {
                    const azienda = cliente.customers_company ? cliente.customers_company.toLowerCase().replace(/ /g, "") : "";
                    const nome = cliente.customers_name ? cliente.customers_name.toLowerCase().replace(/ /g, "") : "";
                    const cognome = cliente.customers_last_name ? cliente.customers_last_name.toLowerCase().replace(/ /g, "") : "";
                    const citta = cliente.customers_city ? cliente.customers_city.toLowerCase().replace(/ /g, "") : "";
                    const provincia = cliente.customers_province ? cliente.customers_province.toLowerCase().replace(/ /g, "") : "";
                    const currentCustomer = `${azienda}${nome}${cognome}${citta}${provincia}`;

                    if (currentCustomer.includes(term)) {
                        return cliente;
                    }
                });
            } else {
                return clienti.value;
            }

            /* if (term.length > 2) {
                return clienti.value.filter((cliente) => {
                    //company, nome/cognome, province
                    //console.log(cliente.customers_company, cliente.customers_name, cliente.customers_last_name);
                    if (cliente.customers_company && cliente.customers_company != "") {
                        return (
                            cliente.customers_company
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_city
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_province
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else {
                        if (cliente.customers_name && cliente.customers_name != "" && cliente.customers_last_name && cliente.customers_last_name != "") {
                            return (
                                cliente.customers_name
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_last_name
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                (cliente.customers_last_name + cliente.customers_name)
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                (cliente.customers_name + cliente.customers_last_name)
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_city
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_province
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                            );
                        } else if (cliente.customers_name && (!cliente.customers_last_name || cliente.customers_last_name == "")) {
                            return (
                                cliente.customers_name
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_city
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_province
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                            );
                        } else if ((!cliente.customers_name || cliente.customers_name == "") && cliente.customers_last_name) {
                            return (
                                cliente.customers_last_name
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_city
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                                cliente.customers_province
                                    .toLowerCase()
                                    .replace(/ /g, "")
                                    .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                            );
                        }
                    }
                });
            } else {
                return clienti.value;
            } */
        });

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (field == null) {
                return " ";
            } else {
                return " - " + field;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Passing customer object ClienteDetail page
         */
        async function openDetailModal(customer) {
            //console.log(customer);
            const modal = await modalController.create({
                component: ClienteDetail,
                componentProps: {
                    data: customer,
                },
            });
            return modal.present();
        }

        /**
         * Get all clienti
         */
        async function loadClienti() {
            loading.value = true;
            try {
                //clienti.value = await apiClienti.getClienti();
                clienti.value = await apiClienti.getClienti();
            } catch (error) {
                //clienti.value = [];
                clienti.value = [];
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         ** Open modal to create new customer
         */
        async function newCustomer() {
            const modal = await modalController.create({
                component: ModalNewCustomer,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    clienti.value = [detail.data, ...clienti.value];
                    //aggiungo anche nel db locale
                    dbCustomers.collection("clienti").add(detail.data, detail.data.customers_id);
                    openToast("Cliente inserito con successo", "toast_success");
                }
            });
            return modal.present();
        }

        async function deleteDb() {
            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbCustomers.delete().then((response) => {
                console.log(response);
            });
        }

        /**
         * Delete Localbase databases and clear localStorage
         * Redirect user to Login page
         */
        function logOut() {
            deleteDb().then(() => {
                localStorage.clear();
                router.replace("/login");
            });
        }

        /**
         * Logout automatico se utente è disabilitato
         */
        async function loadUserData() {
            try {
                const response = await apiUtenti.getUser(userID);
                if (response.status === 200 && response.data.data.length != 0) {
                    const loggedUser = response.data.data;
                    if (loggedUser.users_active != "1") {
                        logOut();
                    }
                } else {
                    openToast("Errore durante la richiesta dei dati dell'utente", "toast_danger");
                }
            } catch (error) {
                console.error(error);
            }
        }

        /**
         * ! Scarica clienti da api o usa db locale in base all'impostazione della pagina Profilo
         */
        const localMode = JSON.parse(localStorage.getItem("localMode"));
        //console.log(localMode);

        async function getClienti() {
            loading.value = true;
            try {
                if (!localMode) {
                    await loadClienti();
                } else {
                    const customerDB = await getCustomers();
                    //trovati clienti in db locale
                    if (customerDB.length != 0) {
                        clienti.value = customerDB;
                        loading.value = false;
                    } else {
                        await loadClienti();
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                loading.value = false;
            }
        }

        /*         async function getClienti() {
            loading.value = true;
            try {
                const customerDB = await getCustomers();
                //trovati clienti in db locale
                if (customerDB.length != 0) {
                    clienti.value = customerDB;
                    loading.value = false;
                } else {
                    await loadClienti();
                }
            } catch (error) {
                console.log(error);
            } finally {
                loading.value = false;
            }
        } */

        onIonViewWillEnter(() => {
            loadUserData();
        });

        onMounted(() => {
            //GET CLIENTI DATA
            //getCustomers();
            getClienti();
        });

        return {
            loading,
            dateFormat,
            add,
            refresh,
            checkField,
            //New field
            openDetailModal,
            //new field - 20220427
            loadClienti,
            clienti,
            searchQuery,
            //searchedCustomers,
            riferimentoCliente,
            indirizzoCliente,
            newCustomer,
            //            searchedCustomers: shallowRef(searchedCustomers),
            searchedCustomers,
            CustomerItem,
        };
    },
};
